<template>
  <div>
    <div class="bar tooltip" :class="statusCode">
      <Tooltip :hoverText="status" position="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Status",
  components: {
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
  },
  props: {
    statusCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    status() {
      let tooltipText;
      switch (this.statusCode) {
        case "Orange":
          tooltipText = "Ingredient is present in only one CUC";
          break;
        case "Green":
          tooltipText =
            "Ingredient present in both CUC and the % used is also same";
          break;
        case "Red":
          tooltipText =
            "Ingredient present in both CUC but the % used does not match";
          break;
      }
      return tooltipText;
    },
  },
};
</script>

<style lang="scss">
.bar {
  width: 40px;
  height: 10px;
  border-radius: 2px;
  z-index: 3;
}
.Orange {
  background-color: #ffc031;
}
.Green {
  background-color: #70dabc;
}
.Red {
  background-color: #ff5f73;
}
.bar {
  width: 40px;
  height: 10px;
  z-index: auto;
}
.comparison-wrapper table td.tooltip {
  overflow: visible !important;
}
</style>
